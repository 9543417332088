import React, { Fragment, useContext } from "react";
import { Form, Formik } from "formik";

import Section from "elements/Section";
import ActionButtons from "elements/ActionButtons";
import Message from "elements/Message";

import { generateFormField } from "elements/Form/services/form.js";
import { ConsultationContext } from "../../ConsultationContext/ConsultationContext";

const BookingSummaryConsent = ({
	sectionFormFields,
	validationSchema,
	handleSubmit,
	pageContext,
	loading,
	accessRule,
}) => {
	const { consultationState } = useContext(ConsultationContext);

	let consentAndAuthorizationOptionsCount = !!accessRule?.redirectLinks ? 2 : 6;

	return (
		<Formik
			initialValues={{ ...consultationState }}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, setFieldValue, errors }) => (
				<Form>
					{sectionFormFields.map((section) => (
						<Section title={section?.section}>
							{section?.fields.map((field) => {
								if (!field?.referenceAnswer) {
									return (
										<Fragment>
											{generateFormField({
												formFields: section?.fields,
												formField: field,
												values,
												setFieldValue,
											})}
											{!!field?.addDividerAfterField && (
												<hr className="has-background-light" />
											)}
										</Fragment>
									);
								}
								return null;
							})}
						</Section>
					))}

					{values.consentAndAuthorization.length !==
						consentAndAuthorizationOptionsCount && (
						<Message>
							You may have missed some required fields. Please scan through the
							form and check if your information is complete.
						</Message>
					)}

					<ActionButtons
						back={{
							link: pageContext.backPath,
						}}
						submit={{
							label: "Request Teleconsult",
							loading,
							disabled:
								values?.consentAndAuthorization?.length !==
								consentAndAuthorizationOptionsCount,
						}}
					/>
				</Form>
			)}
		</Formik>
	);
};

export default BookingSummaryConsent;
