import firebase from "firebase";

import {
	generateZendeskTemplate,
	sendToZendesk,
} from "services/zendeskService";

import {
	generateConsultFirebaseDocument,
	appointmentZendeskTemplate,
} from "./generateTemplates";

import { getSignedInUser } from "../../../Auth/services/user";
import axios from "axios";

export const computeKesslerScore = (kessler6) => {
	let answers = Object.values(kessler6);

	const getScore = (answer) => {
		switch (answer) {
			case "Always":
				return 0;
			case "Often":
				return 1;
			case "Sometimes":
				return 2;
			case "Rarely":
				return 3;
			case "Never":
				return 4;
			default:
				return null;
		}
	};

	let score = answers.reduce(
		(accumulator, currentValue) => accumulator + getScore(currentValue),
		0
	);
	return score;
};

export const sendTeleconsultRequest = async ({
	consultationState,
	callback,
}) => {
	const user = getSignedInUser().userData;
	const accessRule = getSignedInUser().accessRule;
	let programCodes;
	// check if the domain is medgrocer.com then proceed to get the programCode
	if (user?.domain?.eligiblePrograms) {
		programCodes = user?.domain?.eligiblePrograms;
		programCodes = programCodes?.filter((code) => code.includes("MH"));
	} else {
		programCodes = user?.accessCode;
	}

	const { mhRequestZendeskTags } = accessRule;
	let requestData = { user, consultationState, programCodes };

	const {
		kesslerNervous,
		kesslerHopeless,
		kesslerRestless,
		kesslerDepressed,
		kesslerEffort,
		kesslerWorthless,
	} = consultationState;

	try {
		let tags = mhRequestZendeskTags
			? mhRequestZendeskTags
			: ["new_request", "maxicare", "maxicare_mh", "self"];
		if (process.env.GATSBY_ENV !== "production") tags.push("test");

		let name = `${user?.firstName} ${user?.lastName}`;
		let customFields = [];

		const ZENDESK_USER_UID_FIELD_ID = 900007714463;
		const ZENDESK_KESSLER_SCORE_FIELD_ID = 900008136543;
		const ZENDESK_MOBILE_NUMBER_FIELD_ID = 900007397186;
		const ZENDESK_JSON_FIELD_ID = 900012853803;

		customFields.push({
			id: ZENDESK_USER_UID_FIELD_ID,
			value: user?.authUid,
		});
		customFields.push({
			id: ZENDESK_MOBILE_NUMBER_FIELD_ID,
			value: user?.mobileNumber,
		});
		customFields.push({
			id: ZENDESK_JSON_FIELD_ID,
			value: JSON.stringify(generateConsultFirebaseDocument({ requestData })),
		});

		const kessler6 = {
			kesslerNervous,
			kesslerHopeless,
			kesslerRestless,
			kesslerDepressed,
			kesslerEffort,
			kesslerWorthless,
		};

		customFields.push({
			id: ZENDESK_KESSLER_SCORE_FIELD_ID,
			value: computeKesslerScore(kessler6),
		});

		const {
			hasConsultedWithPsychOutsideProgram,
			experienceWithPsychOutsideProgram,
			hasConsultedWithPsychInProgram,
			previousPsychName,
			hasBeenDiagnosed,
			mentalIllnessDiagnosis,
			hasFamilyHistory,
			specificFamilyHistory,
			hasBeenHospitalized,
			specificHospitalizationDetails,
			isTakingPsychMeds,
			psychMedsDetails,
			isTakingNonPsychMeds,
			nonPsychMedsDetails,
			hasDrugAllergies,
			drugAllergiesDetails,
			causeOfDistress,
			specificCauseOfDistress,
			durationCurrentCauseOfDistress,
			currentMentalHealthCondition,

			hasWishedToBeDead,
			hadSuicidalThoughts,
			hasThoughtSuicidalMethod,
			hadSuicidalIntent,
			hadSuicidalIntentWithPlan,
			hadActedOnSuicidalThoughts,
			suicideAttemptRecency,
			nonSuicidalSelfInjury,
			hasThoughtsOfHarmingOthers,

			firstChoiceSchedule,
			secondChoiceSchedule,
			thirdChoiceSchedule,
			ifScheduleUnavailable,
			specificIfScheduleUnavailable,
			therapistPreference,
			otherNotes,
		} = consultationState;

		const site =
			user?.employee?.siteName ||
			user?.company ||
			user?.organizationTradeName ||
			user?.domain?.orgTradeName ||
			accessRule?.organizationTradeName;

		user.site = site;

		let zendeskTemplate = generateZendeskTemplate({
			subject: `[${site}] ${name}: Teleconsult Request`,
			personalEmail: user?.personalEmail || user?.email,
			email: user?.email,
			name: name,
			tags,
			template: appointmentZendeskTemplate,
			templateObjects: requestData,
			customFields: customFields,
			priority: "normal",
		});

		const zendeskResponse = await sendToZendesk({
			request: zendeskTemplate,
		});

		let document = generateConsultFirebaseDocument({
			requestData,
			zendeskId: zendeskResponse?.data?.request?.id,
		});

		const docRef = await firebase
			.firestore()
			.collection("consultations")
			.add(document);

		await axios.post(process.env.GATSBY_MAKE_PROCESS_MH, {
			userData: user,
			requestData: {
				hasConsultedWithPsychOutsideProgram,
				experienceWithPsychOutsideProgram,
				hasConsultedWithPsychInProgram,
				previousPsychName,
				hasBeenDiagnosed,
				mentalIllnessDiagnosis,
				hasFamilyHistory,
				specificFamilyHistory,
				hasBeenHospitalized,
				specificHospitalizationDetails,
				isTakingPsychMeds,
				psychMedsDetails,
				isTakingNonPsychMeds,
				nonPsychMedsDetails,
				hasDrugAllergies,
				drugAllergiesDetails,
				causeOfDistress,
				specificCauseOfDistress,
				durationCurrentCauseOfDistress,
				currentMentalHealthCondition,

				kesslerNervous,
				kesslerHopeless,
				kesslerRestless,
				kesslerDepressed,
				kesslerEffort,
				kesslerWorthless,

				hasWishedToBeDead,
				hadSuicidalThoughts,
				hasThoughtSuicidalMethod,
				hadSuicidalIntent,
				hadSuicidalIntentWithPlan,
				hadActedOnSuicidalThoughts,
				suicideAttemptRecency,
				nonSuicidalSelfInjury,
				hasThoughtsOfHarmingOthers,

				firstChoiceSchedule,
				secondChoiceSchedule,
				thirdChoiceSchedule,
				ifScheduleUnavailable,
				specificIfScheduleUnavailable,
				therapistPreference,
				otherNotes,
			},
			type: "selfRequest",
			zendeskId: zendeskResponse?.data?.request?.id,
			firebaseId: docRef?.id,
			kessler: computeKesslerScore(kessler6),
			website: accessRule?.homeUrl?.replace("https://", "").replace("/", ""),
			programCode: programCodes,
			environment:
				process.env.GATSBY_ENV === "production" ? "production" : "develop",
		});

		if (callback) callback();
	} catch (error) {
		console.error(error);
	}
};
